<template>
  <div class="min-h-full flex flex-col">
    <div class="flex justify-between items-center mb-10">
      <div class="flex flex-col">
        <h4 class="text-3xl sm:text-4xl font-bold leading-tight">
          Promotional
          Opportunities
        </h4>
        <div class="mt-6 sm:mt-10 text-gray-500 font-light text-xl">
          Choose how much exposure you want this interview to have. You can select as many as you'd like.
        </div>
      </div>
      <!-- Step counter -->
      <StepsCounter class="ml-4 mb-48" v-if="steps.length" :stepCount="steps.length" :currentStep="currentStep"/>
    </div>

    <ValidationObserver ref="questionsForm" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(saveUpsell)">
        <div class="space-y-8">
          <label
            v-for="(upsellPackage, index) in availableUpsellPackages"
            :key="`package-${index}`"
            class="block rounded-lg border-2 flex-1 px-6 py-4 cursor-pointer select-none hover:bg-gray-50"
            :class="{'border-pink-500': isSelectedPackage(upsellPackage)}"
          >
            <input type="checkbox"
              :checked="isSelectedPackage(upsellPackage)"
              @change="toggleSelectedPackage(upsellPackage)"
              class="hidden"
            >
            <div class="header flex items-center justify-between text-blue-800">
              <h3 class="text-blue-900 text-xl font-bold">
                {{ upsellPackage.title }}
              </h3>
              <span class="ml-2 price text-2xl font-bold">
                ${{ upsellPackage.price }}
              </span>
            </div>
            <div class="description mt-2 leading-normal text-lg text-gray-500 whitespace-pre-wrap">{{ upsellPackage.description }}</div>
            <div class="meta mt-6 space-y-2" v-if="upsellPackage.url || upsellPackage.social_handle">
              <p v-if="upsellPackage.url && upsellPackage.url.length">
                <a :href="upsellPackage.url" target="_blank" class="text-indigo-500">
                  {{ upsellPackage.url }}
                </a>
              </p>
              <p v-if="upsellPackage.social_handle && upsellPackage.social_handle.length" class="text-pink-500">
                {{ upsellPackage.social_handle }}
              </p>
            </div>
          </label>
        </div>
        <div class="mt-12 flex justify-center">
          <button type="submit" class="rounded-full h-16 sm:h-20 bg-indigo-gradiant text-white text-xl font-bold flex justify-center items-center uppercase px-16 disabled:opacity-50" :disabled="loading">
            <span v-if="loading">
              <loading-icon class="h-5 w-5"/>
            </span>
            <span v-else>Continue</span>
          </button>
        </div>
        <div class="text-center mt-4">
          <button @click.prevent="prevStep" type="button" class="text-sm text-pink-500 hover:underline">Previous step</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import StepsCounter from '@/components/StepsCounter'

  export default {
    components: {
      StepsCounter
    },

    data() {
      return {
        loading: false,
        upsells: [],
      }
    },

    computed: {
      steps() {
        return this.$store.getters['guestBooking/enabledSteps']
      },

      currentStep() {
        return this.$store.state.guestBooking.currentStep
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      show() {
        return this.$store.getters['guestBooking/show']
      },

      guest() {
        return this.$store.getters['guest/getGuest']
      },

      availableUpsellPackages() {
        return this.$store.getters['guestBooking/availableUpsellPackages']
      },

      selectedUpsells() {
        return this.$store.getters['guestBooking/upsells']
      }
    },

    methods: {
      saveUpsell() {
        this.$store.commit('guestBooking/setUpsells', this.upsells)

        this.nextStep()
      },

      isSelectedPackage(upsellPackage) {
        return !! this.upsells.find(selected => selected.id === upsellPackage.id)
      },

      toggleSelectedPackage(upsellPackage) {
        let index = this.upsells.findIndex(selected => selected.id === upsellPackage.id)

        if (index === -1) {
          return this.upsells.push(upsellPackage)
        }

        this.upsells.splice(index, 1)
      },

      nextStep() {
        let nextStepRoute = this.steps[this.currentStep].route

        this.$router.push({ name: nextStepRoute })
      },

      prevStep() {
        let prevStepRoute = this.steps[this.currentStep - 2].route

        this.$router.push({ name: prevStepRoute })
      },

      determineStepNumber() {
        let step = this.steps.findIndex(step => step.name == 'upsells')

        if (step === -1) {
          return this.$router.push({ name: this.steps[0].route })
        }

        this.$store.commit('guestBooking/setCurrentStep', step + 1)
      },
    },

    mounted() {
      this.determineStepNumber()

      if (! this.show || ! this.show.id) {
        this.$router.push({name: this.steps[0].route})
        return
      }

      this.upsells = [...this.selectedUpsells]
    }
  }
</script>
